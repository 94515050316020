import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DialogInterventionCollaboratorAddComponent } from './dialog-intervention-collaborator-add/dialog-intervention-collaborator-add.component';
import { DialogInterventionCollaboratorRemoveComponent } from './dialog-intervention-collaborator-remove/dialog-intervention-collaborator-remove.component';
import { DialogInterventionCollaboratorEditComponent } from './dialog-intervention-collaborator-edit/dialog-intervention-collaborator-edit.component';
import { DialogInterventionInstanceDetailComponent } from './dialog-intervention-instance-detail/dialog-intervention-instance-detail.component';
import { DialogInterventionInstanceCreateComponent } from './dialog-intervention-instance-create/dialog-intervention-instance-create.component';
import { DialogInterventionInstanceDeleteComponent } from './dialog-intervention-instance-delete/dialog-intervention-instance-delete.component';
import { DialogInterventionInstanceUpdateComponent } from './dialog-intervention-instance-update/dialog-intervention-instance-update.component';
import { DialogInterventionDiaryPreviewComponent } from './dialog-intervention-diary-preview/dialog-intervention-diary-preview.component';
import { DialogGroupCreationComponent } from './dialog-group-creation/dialog-group-creation.component';
import { DialogPatientVerificationResendComponent } from './dialog-patient-verification-resend/dialog-patient-verification-resend.component';
import { DialogPatientDiaryAnswersheetPreviewComponent } from './dialog-patient-diary-answersheet-preview/dialog-patient-diary-answersheet-preview.component';
import { DialogPatientAccountDeleteComponent } from './dialog-patient-account-delete/dialog-patient-account-delete.component';
import { DialogGroupMemberInvitationCheckComponent } from './dialog-group-member-invitation-check/dialog-group-member-invitation-check.component';
import { DialogOrganisationCollaboratorAddComponent } from './dialog-organisation-collaborator-add/dialog-organisation-collaborator-add.component';
import { DialogOrganisationStudyCollaboratorManagementComponent } from './dialog-organisation-study-collaborator-management/dialog-organisation-study-collaborator-management.component';
import { DialogInterventionPublishTestComponent } from './dialog-intervention-publish-test/dialog-intervention-publish-test.component';
import { DialogOrganisationCollaboratorRemoveComponent } from './dialog-organisation-collaborator-remove/dialog-organisation-collaborator-remove.component';
import { DialogOrganisationCollaboratorEditComponent } from './dialog-organisation-collaborator-edit/dialog-organisation-collaborator-edit.component';
import { DialogInterventionPublishOfficialComponent } from './dialog-intervention-publish-official/dialog-intervention-publish-official.component';
import { DialogInterventionDeleteComponent } from './dialog-intervention-delete/dialog-intervention-delete.component';
import { DialogConversationCreateComponent } from './dialog-conversation-create/dialog-conversation-create.component';
import { DialogGroupMemberRemoveComponent } from './dialog-group-member-remove/dialog-group-member-remove.component';
import { DialogPatientInvitationsComponent } from './dialog-patient-invitations/dialog-patient-invitations.component';
import { DialogStudyMediaGalleryComponent } from './dialog-study-media-gallery/dialog-study-media-gallery.component';
import { DialogGroupMemberCodeUpdateComponent } from './dialog-group-member-code-update/dialog-group-member-code-update.component';
import { DialogAdminStudyOrganisationComponent } from './dialog-admin-study-organisation/dialog-admin-study-organisation.component';
import { DialogInterventionInstanceReportComponent } from './dialog-intervention-instance-report/dialog-intervention-instance-report.component';
import { DialogStudyReportComponent } from './dialog-study-report/dialog-study-report.component';
import { UtilsModule } from '../utils/utils.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomScrollDirective } from './utils/directives/custom-scroll/custom-scroll.directive';
import { InterventionInstanceModule } from '../intervention-instance/intervention-instance.module';
import { AnswersheetModule } from '../answersheet/answersheet.module';
import { QuestionnaireModule } from '../questionnaire/questionnaire.module';
import { DialogBuddyInstanceCreateComponent } from './dialog-buddy-instance-create/dialog-buddy-instance-create.component';
import { DialogBuddyInstanceDeleteComponent } from './dialog-buddy-instance-delete/dialog-buddy-instance-delete.component';
import { DialogBuddyInstanceDetailComponent } from './dialog-buddy-instance-detail/dialog-buddy-instance-detail.component';
import { DialogBuddyInstanceUpdateComponent } from './dialog-buddy-instance-update/dialog-buddy-instance-update.component';
import { DialogAnswersheetReportComponent } from './dialog-answersheet-report/dialog-answersheet-report.component';
import { DialogInterventionLessonPreviewComponent } from './dialog-intervention-lesson-preview/dialog-intervention-lesson-preview.component';
import { DialogDownloadsComponent } from './dialog-downloads/dialog-downloads.component';
import { DialogAdminUserUpdateComponent } from './dialog-admin-user-update/dialog-admin-user-update.component';
import { DialogAdminUserDeleteComponent } from './dialog-admin-user-delete/dialog-admin-user-delete.component';
import { DialogAdminUserCreateComponent } from './dialog-admin-user-create/dialog-admin-user-create.component';
import { DialogAdminOrganisationCreateComponent } from './dialog-admin-organisation-create/dialog-admin-organisation-create.component';
import { DialogAdminOrganisationUpdateComponent } from './dialog-admin-organisation-update/dialog-admin-organisation-update.component';
import { DialogAdminOrganisationDeleteComponent } from './dialog-admin-organisation-delete/dialog-admin-organisation-delete.component';
import { DialogAdminOrganisationDetailComponent } from './dialog-admin-organisation-detail/dialog-admin-organisation-detail.component';
import { DialogAdminStudyCreateComponent } from './dialog-admin-study-create/dialog-admin-study-create.component';
import { DialogAdminStudyDeleteComponent } from './dialog-admin-study-delete/dialog-admin-study-delete.component';
import { DialogAdminStudyUpdateComponent } from './dialog-admin-study-update/dialog-admin-study-update.component';
import { DialogAdminAccountDeletionRequestsComponent } from './dialog-admin-account-deletion-requests/dialog-admin-account-deletion-requests.component';
import { DialogSkillElementsComponent } from './dialog-skill-elements/dialog-skill-elements.component';
import { DialogInterventionInstanceConfigurationComponent } from './dialog-intervention-instance-configuration/dialog-intervention-instance-configuration.component';
import { DialogInstanceQuestionnaireConfigurationDiaryComponent } from './dialog-instance-questionnaire-configuration-diary/dialog-instance-questionnaire-configuration-diary.component';
import { DialogInstanceCollaboratorAddComponent } from './dialog-instance-collaborator-add/dialog-instance-collaborator-add.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DialogInterventionInstanceReminderCreateComponent } from './dialog-intervention-instance-reminder-create/dialog-intervention-instance-reminder-create.component';
import { DialogInterventionInstanceReminderDetailComponent } from './dialog-intervention-instance-reminder-detail/dialog-intervention-instance-reminder-detail.component';
import { DialogBuddyInstanceSwapComponent } from './dialog-buddy-instance-swap/dialog-buddy-instance-swap.component';
import { DialogBuddyActivityReportComponent } from './dialog-buddy-activity-report/dialog-buddy-activity-report.component';
import { DialogAccountInterventionInstanceAssignmentComponent } from './dialog-account-intervention-instance-assignment/dialog-account-intervention-instance-assignment.component';
import { DialogInterventionPublishReviewComponent } from './dialog-intervention-publish-review/dialog-intervention-publish-review.component';
import { DialogOrganisationAccountCreationComponent } from './dialog-organisation-account-creation/dialog-organisation-account-creation.component';
import { DialogOrganisationAccountDeleteComponent } from './dialog-organisation-account-delete/dialog-organisation-account-delete.component';
import { DialogCodebookComponent } from './dialog-codebook/dialog-codebook.component';
import { CodebookModule } from '../codebook/codebook.module';
import { DialogAccessCodeComponent } from './dialog-access-code/dialog-access-code.component';
import { DirectiveModule } from '../directive/directive.module';
import { QRCodeModule } from 'angularx-qrcode';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');
@NgModule({
  declarations: [
    DialogInterventionInstanceDetailComponent,
    DialogInterventionCollaboratorAddComponent,
    DialogInterventionCollaboratorEditComponent,
    DialogInterventionCollaboratorRemoveComponent,
    DialogInterventionInstanceCreateComponent,
    DialogInterventionInstanceDeleteComponent,
    DialogInterventionInstanceUpdateComponent,
    DialogInterventionDiaryPreviewComponent,
    DialogGroupCreationComponent,
    DialogPatientVerificationResendComponent,
    DialogPatientDiaryAnswersheetPreviewComponent,
    DialogPatientAccountDeleteComponent,
    DialogGroupMemberInvitationCheckComponent,
    DialogOrganisationCollaboratorAddComponent,
    DialogOrganisationCollaboratorEditComponent,
    DialogOrganisationCollaboratorRemoveComponent,
    DialogOrganisationStudyCollaboratorManagementComponent,
    DialogInterventionPublishTestComponent,
    DialogInterventionPublishOfficialComponent,
    DialogInterventionDeleteComponent,
    DialogConversationCreateComponent,
    DialogGroupMemberRemoveComponent,
    DialogGroupMemberCodeUpdateComponent,
    DialogStudyMediaGalleryComponent,
    DialogPatientInvitationsComponent,
    DialogAdminStudyOrganisationComponent,
    DialogInterventionInstanceReportComponent,
    DialogStudyReportComponent,
    CustomScrollDirective,
    DialogBuddyInstanceCreateComponent,
    DialogBuddyInstanceDeleteComponent,
    DialogBuddyInstanceDetailComponent,
    DialogBuddyInstanceUpdateComponent,
    DialogAnswersheetReportComponent,
    DialogInterventionLessonPreviewComponent,
    DialogDownloadsComponent,
    DialogAdminUserUpdateComponent,
    DialogAdminUserDeleteComponent,
    DialogAdminUserCreateComponent,
    DialogAdminOrganisationCreateComponent,
    DialogAdminOrganisationUpdateComponent,
    DialogAdminOrganisationDeleteComponent,
    DialogAdminOrganisationDetailComponent,
    DialogAdminStudyCreateComponent,
    DialogAdminStudyDeleteComponent,
    DialogAdminStudyUpdateComponent,
    DialogAdminAccountDeletionRequestsComponent,
    DialogSkillElementsComponent,
    DialogInterventionInstanceConfigurationComponent,
    DialogInstanceQuestionnaireConfigurationDiaryComponent,
    DialogInstanceCollaboratorAddComponent,
    DialogInterventionInstanceReminderCreateComponent,
    DialogInterventionInstanceReminderDetailComponent,
    DialogBuddyInstanceSwapComponent,
    DialogBuddyActivityReportComponent,
    DialogAccountInterventionInstanceAssignmentComponent,
    DialogInterventionPublishReviewComponent,
    DialogOrganisationAccountCreationComponent,
    DialogOrganisationAccountDeleteComponent,
    DialogCodebookComponent,
    DialogAccessCodeComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    UtilsModule,
    InterventionInstanceModule,
    AnswersheetModule,
    QuestionnaireModule,
    CodebookModule,
    DirectiveModule,
    QRCodeModule
  ],
  exports: [
    DialogInterventionInstanceDetailComponent,
    DialogInterventionCollaboratorAddComponent,
    DialogInterventionCollaboratorEditComponent,
    DialogInterventionCollaboratorRemoveComponent,
    DialogInterventionInstanceCreateComponent,
    DialogInterventionInstanceDeleteComponent,
    DialogInterventionInstanceUpdateComponent,
    DialogInterventionDiaryPreviewComponent,
    DialogGroupCreationComponent,
    DialogPatientVerificationResendComponent,
    DialogPatientDiaryAnswersheetPreviewComponent,
    DialogPatientAccountDeleteComponent,
    DialogGroupMemberInvitationCheckComponent,
    DialogOrganisationCollaboratorAddComponent,
    DialogOrganisationCollaboratorEditComponent,
    DialogOrganisationCollaboratorRemoveComponent,
    DialogOrganisationStudyCollaboratorManagementComponent,
    DialogInterventionPublishTestComponent,
    DialogInterventionPublishOfficialComponent,
    DialogInterventionDeleteComponent,
    DialogConversationCreateComponent,
    DialogGroupMemberRemoveComponent,
    DialogGroupMemberCodeUpdateComponent,
    DialogStudyMediaGalleryComponent,
    DialogPatientInvitationsComponent,
    DialogAdminStudyOrganisationComponent,
    DialogInterventionInstanceReportComponent,
    DialogStudyReportComponent,
    DialogBuddyInstanceCreateComponent,
    DialogBuddyInstanceDeleteComponent,
    DialogBuddyInstanceDetailComponent,
    DialogBuddyInstanceUpdateComponent,
    DialogAnswersheetReportComponent,
    DialogInterventionLessonPreviewComponent,
    DialogDownloadsComponent,
    DialogAdminUserUpdateComponent,
    DialogAdminUserDeleteComponent,
    DialogAdminUserCreateComponent,
    DialogAdminOrganisationCreateComponent,
    DialogAdminOrganisationUpdateComponent,
    DialogAdminOrganisationDeleteComponent,
    DialogAdminOrganisationDetailComponent,
    DialogAdminStudyCreateComponent,
    DialogAdminStudyDeleteComponent,
    DialogAdminStudyUpdateComponent,
    DialogAdminAccountDeletionRequestsComponent,
    DialogSkillElementsComponent,
    DialogInterventionInstanceConfigurationComponent,
    DialogInstanceQuestionnaireConfigurationDiaryComponent,
    DialogInstanceCollaboratorAddComponent,
    DialogBuddyInstanceSwapComponent,
    DialogBuddyActivityReportComponent,
    DialogAccountInterventionInstanceAssignmentComponent,
    DialogInterventionPublishReviewComponent,
    DialogOrganisationAccountDeleteComponent,
    DialogCodebookComponent,
    DialogAccessCodeComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DialogModule {}
