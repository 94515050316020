import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { BehaviorSubject, iif, mergeMap, Observable, of, skip, Subscription, take, throwError } from 'rxjs';
import { AccessCodeBatchInterface } from 'src/app/models/interface/access-code-batch.interface';
import { AccessCodeInterface } from 'src/app/models/interface/access-code.interface';
import { StudyInterface } from 'src/app/models/interface/study/study.interface';
import { HelperService } from 'src/app/services/helper/helper.service';
import { StudyStore } from 'src/app/store/study/component-store/study.store';

import * as htmlToImage from 'html-to-image';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { faCheck, faHourglass, faQrcode, faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import { UserInterface } from 'src/app/models/interface/user.interface';
import { faStarHalfStroke } from '@fortawesome/free-regular-svg-icons';

const download = require('downloadjs/download');

type AccessCodeDialogData = {
  group: StudyInterface;
  collaborators: UserInterface[];
};

@Component({
  selector: 'app-dialog-access-code',
  templateUrl: './dialog-access-code.component.html',
  styleUrls: ['./dialog-access-code.component.scss'],
  providers: [StudyStore]
})
export class DialogAccessCodeComponent implements OnInit, OnDestroy {
  // Icons
  faRedo = faRedo;
  faTimes = faTimes;
  faEllipsisH = faEllipsisH;
  faFilePdf = faFilePdf;
  faFileCsv = faFileCsv;
  faClock = faClock;
  faInfoCircle = faInfoCircle;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faQrcode = faQrcode;
  faUser = faUser;
  faHourglass = faHourglass;
  faCheck = faCheck;
  faStarHalfStroke = faStarHalfStroke;
  faStar = faStar;

  // Data provided by StudyService
  public group: StudyInterface;
  public collaborators: UserInterface[];

  public showOptional$: Observable<boolean>;
  public showOptionalSubject = new BehaviorSubject<boolean>(false);

  public isLoading$: Observable<boolean>;

  public submitted = false;

  public selectedTabIndex = 0;

  public pagedAccessCodeBatches$: Observable<AccessCodeBatchInterface[]>;

  public accessCodeBatchesSubject = new BehaviorSubject<AccessCodeBatchInterface[]>([]);
  public generatedAccessCodesSubject = new BehaviorSubject<AccessCodeInterface[]>([]);
  public generatedAccessCodes$: Observable<AccessCodeInterface[]>;
  public accessCodeBatches$: Observable<AccessCodeBatchInterface[]>;

  public createAccessCodeBatches$: Observable<any>;

  public numberOfCodes = '1';
  public numberOfValidity = '30';
  public inputStudyCodes: string;
  public description = '';

  public studyCodes: string[] = [];

  public param = { numbers_study_code: this.studyCodes.length };

  public generateAccessCodeBatchResponse = new BehaviorSubject<string>('DEFAULT');

  public usedStudyCodesSubject = new BehaviorSubject<string>(null);

  private isLoadingSubject = new BehaviorSubject<boolean>(true);

  private pagedAccessCodeBatchesSubject = new BehaviorSubject<AccessCodeBatchInterface[]>([]);

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AccessCodeDialogData,
    private studyStore: StudyStore,
    private helperService: HelperService,
  ) {
    this.showOptional$ = this.showOptionalSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();

    this.accessCodeBatches$ = this.studyStore.accessCodeBatchesResponse$;
    this.createAccessCodeBatches$ = this.studyStore.createAccessCodeResponse$;
    this.generatedAccessCodes$ = this.generatedAccessCodesSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.group = this.data.group;
    this.collaborators = this.data.collaborators;
    this.pagedAccessCodeBatches$ = this.pagedAccessCodeBatchesSubject.asObservable();
    this.loadAccessCodeBatch();
  }

  public loadAccessCodeBatch(): void {
    this.studyStore.getAccessCodeBatchesStudy({ studyId: this.group.id });
    this.accessCodeBatches$.subscribe((allJobs: AccessCodeBatchInterface[]) => {
      this.accessCodeBatchesSubject.next(allJobs.sort((a, b) => b.id - a.id));
      this.pagedAccessCodeBatchesSubject.next(this.accessCodeBatchesSubject.value.slice(0, 20));
      this.isLoadingSubject.next(false);
    });
  }

  public updatePagedAccessCodeBatches(event: any) {
    if (event) {
      this.pagedAccessCodeBatchesSubject.next(event);
    }
  }

  public trackByAccessCodeBatchId(_index: number, element: AccessCodeBatchInterface): number {
    return element.id;
  }

  public setSelectedTabIndex(event: number): void {
    this.selectedTabIndex = event;
  }

  public generateAccessCodeBatch(): void {
    // Disabled button still allows click, but invalid data shouldn't be submittable
    if (this.anyInvalidData()) {
      return;
    }

    if (this.generateAccessCodeBatchResponse.value === 'FAILURE' || this.generateAccessCodeBatchResponse.value === 'SUCCESS') {
      this.generateAccessCodeBatchResponse.next('DEFAULT');
      return;
    }

    if (this.generateAccessCodeBatchResponse.value === 'DEFAULT') {
      this.usedStudyCodesSubject.next(null);
      this.generateAccessCodeBatchResponse.next('LOADING');
      this.studyCodes = [];
      if (this.inputStudyCodes !== '' && this.inputStudyCodes != null) {
        this.studyCodes = this.inputStudyCodes.replace(/\s+/g, '').split(',');
      }
      this.studyStore.createAccessCode({
        studyId: this.group.id,
        number_access_codes: this.parseNumber(this.numberOfCodes, 1),
        valid_for: this.parseNumber(this.numberOfValidity, undefined),
        study_codes: this.studyCodes.length > 0 ? this.studyCodes : undefined,
        description: this.description || undefined,
      });
      this.createAccessCodeBatches$
        .pipe(
          skip(1),
          take(1),
          mergeMap((result: any) => iif(() => result instanceof HttpErrorResponse, throwError(() => result), of(result)))
        )
        .subscribe({
          next: (result: any) => {
            this.generatedAccessCodesSubject.next(result);
            this.loadAccessCodeBatch();
            this.convertToCSV();
            this.generateAccessCodeBatchResponse.next('SUCCESS');
            setTimeout(() => {
              this.generateAccessCodeBatchResponse.next('DEFAULT');
              this.submitted = false;
            }, 2500);
          },
          error: errorResp => {
            if (errorResp instanceof HttpErrorResponse) {
              const error = errorResp.error?.errors[0];
              if (error) {
                if (error.code.toString() === '1515') {
                  this.usedStudyCodesSubject.next(error.detail.replace('The following study codes are already in use:', ''));
                  this.showOptionalSubject.next(false);
                }
              }
            }

            this.generateAccessCodeBatchResponse.next('FAILURE');
            this.generatedAccessCodesSubject.next([]);
            setTimeout(() => {
              this.generateAccessCodeBatchResponse.next('DEFAULT');
              this.submitted = false;
            }, 2500);
          },
        });
    }
  }

  private parseNumber<T>(num: string, x: T): number | T {
    if (!num) {
      return x;
    }
    return parseInt(num, 10) || x;
  }

  public invalidNumberOfCodes(): boolean {
    return !parseInt(this.numberOfCodes, 10);
  }

  public invalidValidityDays(): boolean {
    return !!this.numberOfValidity && !parseInt(this.numberOfValidity, 10);
  }

  public notMatchingNumbersOfAccessCodes(): boolean {
    if (this.inputStudyCodes !== '' && this.inputStudyCodes != null) {
      return (
        this.invalidNumberOfCodes() ||
        this.inputStudyCodes.replace(/\s+/g, '').split(',').length !== parseInt(this.numberOfCodes, 10)
      );
    }
    return false;
  }

  public anyInvalidData(): boolean {
    return (
      this.invalidNumberOfCodes() ||
      this.invalidValidityDays() ||
      this.notMatchingNumbersOfAccessCodes()
    );
  }

  public getAllCodesAsPNG(): void {
    if (this.generatedAccessCodesSubject.value.length > 0) {
      for (const index of this.generatedAccessCodesSubject.value.keys()) {
        const node = document.getElementById('access-code_' + index);
        htmlToImage
          .toPng(node)
          .then(dataUrl => download(dataUrl, 'access-code_' + index + '.png'))
          .catch(error => {
            console.error('oops, something went wrong!', error);
          });
      }
    }
  }

  public getAllCodesAsSinglePNG(): void {
    if (this.generatedAccessCodesSubject.value.length > 0) {
      const node = document.getElementById('access-code-list');
      htmlToImage
        .toPng(node)
        .then(dataUrl => download(dataUrl, 'access-code-list.png'))
        .catch(error => {
          console.error('oops, something went wrong!', error);
        });
    }
  }

  public convertToPDF() {
    const data = document.getElementById('access-code-list');
    html2canvas(data).then(canvas => {
      const imgWidth = 213;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const doc = new jspdf('p', 'mm', [imgWidth, imgHeight]);
      doc.addImage(canvas, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');
      doc.save('access-code-list.pdf');
    });
  }

  public convertToCSV() {
    const rows: AccessCodeInterface[] = this.generatedAccessCodesSubject.value;

    const getStudyCode = (registrationUrl: string) => {
      const studyCodeIndex = registrationUrl ? registrationUrl.lastIndexOf('&studyCode=') : -1;
      if (studyCodeIndex !== -1) {
        return ';' + registrationUrl.substring(studyCodeIndex + 11) + ';';
      }
      return ';NULL;';
    };

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      'access_code; study_code; registration_url;\n' +
      rows
        .map(
          codes =>
            codes.attributes.access_code.replace(',', '') +
            getStudyCode(codes.attributes.registration_url) +
            codes.attributes.registration_url +
            ';\n'
        )
        .join('');

    const encodedUri = encodeURI(csvContent);
    const filename = `access-codes-${new Date().toISOString()}.csv`;
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', filename);
    downloadLink.setAttribute('href', encodedUri);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
  }

  // Re-enable, when a method to download images has been implemented
  onChangeURL(url: SafeUrl) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
