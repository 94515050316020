<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'access-code.dialog_access_code_batches' | translate}}</h1>

<div class="content-height" mat-dialog-content>
  <p>{{'access-code.description_of_access_code_batches' | translate}}</p>

  <mat-tab-group (selectedIndexChange)="setSelectedTabIndex($event)" mat-align-tabs="center">
    <mat-tab label="{{'access-code.generate_access_code_batch' | translate}}">
      <div class="my-3">
        <p>{{'access-code.generate_access_code_description' | translate}}</p>
      </div>

      <div>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{'access-code.number_of_codes'| translate}}</mat-label>
          <input min="1" matInput onlyNumber [(ngModel)]="numberOfCodes" required />
        </mat-form-field>
        <div class="mb-3" *ngIf="invalidNumberOfCodes()">
          <mat-error>{{'access-code.number_of_codes_required' | translate}}</mat-error>
        </div>
      </div>

      <div>
        <p class="text-info">{{ 'access-code.generate_access_code_hint' | translate}}</p>
      </div>

      <button class="my-3" [attr.id]="'btnToggleOptional'" (click)="helper.toggleSubject(showOptionalSubject)" mat-button>
        <fa-icon [icon]="(showOptional$ | async) ? faChevronUp : faChevronDown"></fa-icon>
        {{ 'access-code.additional_options' | translate }}
      </button>

      <div [hidden]="showOptional$ | async">
        <div>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'access-code.number_of_validity'| translate}}</mat-label>
            <input min="1" matInput onlyNumber [(ngModel)]="numberOfValidity" />
          </mat-form-field>
          <div class="mb-3" *ngIf="invalidValidityDays()">
            <mat-error>
              {{'access-code.number_of_valid_days_required' | translate}}
            </mat-error>
          </div>
        </div>

        <div *ngIf="usedStudyCodesSubject | async" class="text-danger mt-2">
          <p>{{'access-code.used_study_codes' | translate}} {{usedStudyCodesSubject | async}}</p>
        </div>

        <div>
          <div class="my-3">
            <p>{{'access-code.add_study_code_description' | translate:param}}</p>
          </div>
          <form class="mb-3">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{'access-code.study_codes'| translate}}</mat-label>
              <textarea matInput [(ngModel)]="inputStudyCodes"></textarea>
            </mat-form-field>

            <div class="mb-3" *ngIf="notMatchingNumbersOfAccessCodes()">
              <mat-error>
                {{'access-code.number_of_study_codes_required' | translate}}
              </mat-error>
            </div>
          </form>
        </div>

        <div>
          <form class="mb-3">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{'access-code.description' | translate}}</mat-label>
              <textarea matInput cdkTextareaAutosize [(ngModel)]="description"></textarea>
            </mat-form-field>
          </form>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'access-code.download_generated_code' | translate}}">
      <div class="container text-start">
        <div class="downloadButtons mb-2">
          <button [disabled]="(generatedAccessCodes$ | async).length === 0" class="mb-2" (click)="getAllCodesAsPNG()" mat-button>
            {{'access-code.download_as_pngs' | translate}}
          </button>
          <button [disabled]="(generatedAccessCodes$ | async).length === 0" class="mb-2" (click)="getAllCodesAsSinglePNG()" mat-button>
            {{'access-code.download_as_single_png' | translate}}
          </button>
          <button [disabled]="(generatedAccessCodes$ | async).length === 0" class="mb-2" (click)="convertToPDF()" mat-button>
            {{'access-code.download_as_single_pdf' | translate}}
          </button>
          <button [disabled]="(generatedAccessCodes$ | async).length === 0" class="mb-2" (click)="convertToCSV()" mat-button>
            {{'access-code.download_as_single_csv' | translate}}
          </button>
        </div>
        <div class="m-2" id="access-code-list">
          <div *ngIf="(generatedAccessCodes$ | async).length === 0">
            <p>{{'access-code.no_generated_access_codes' | translate}}</p>
          </div>
          <div class="card m-2" *ngFor="let accessCode of (generatedAccessCodes$ | async); index as i" [attr.id]="'access-code_' + i">
            <div class="card-body row">
              <div class="col-12 col-sm-6">
                <div>
                  <p><strong>{{i+1}}. {{'access-code.registration_url'| translate }}:</strong></p>
                  <p class="break-word">{{accessCode.attributes.registration_url}}</p>

                  <p><strong>{{'access-code.valid_until' | translate }}:</strong></p>
                  <p>{{helper.localizeDateString(helper.convertUnixTimestampToDate(accessCode.attributes.expires_at))}}</p>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="qrcodeImage">
                  <qrcode
                    #parent
                    [elementType]="'img'"
                    [errorCorrectionLevel]="'M'"
                    [qrdata]="accessCode?.attributes?.registration_url"
                    [scale]="3"
                    [width]="5"
                    [allowEmptyString]="true"
                    [className]="'qrcode_' + accessCode.id"
                    [margin]="4"
                    [alt]="accessCode.attributes.registration_url"
                    [title]="accessCode.attributes.registration_url"
                    (qrCodeURL)="onChangeURL($event)"
                  ></qrcode>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'access-code.generated_access_code_batches' | translate}}">
      <div class="container text-start">
        <br />

        <button [attr.id]="'btnLoadAccessCodeBatches'" mat-button (click)="loadAccessCodeBatch()">
          <fa-icon [icon]="faRedo"></fa-icon>
          {{'access-code.reload_access_code' | translate}}
        </button>

        <div *ngIf="(pagedAccessCodeBatches$ | async)?.length > 0; then thenShowAccessCodes else elseNoAccessCodes"></div>
        <ng-template #thenShowAccessCodes>
          <app-pagination-list
            #paginator
            (emitPageChange)="updatePagedAccessCodeBatches($event)"
            [_isLoading]="isLoading$ | async"
            [_items]="accessCodeBatches$ | async"
          ></app-pagination-list>

          <div
            [attr.id]="'accessCodeBatch_' + result.id"
            *ngFor="let result of (pagedAccessCodeBatches$ | async); trackBy: trackByAccessCodeBatchId"
          >
            <mat-card
              appearance="outlined"
              [attr.id]="'batch_' + result.id"
              class="mx-auto mb-3"
              [ngClass]="{'alert-danger': result.attributes.is_valid === 0}"
            >
              <mat-card-content>
                <div class="row mx-2 mb-2">
                  <div class="col-8">
                    <strong>{{'access-code.batch_id' | translate}} #{{result.id}}</strong>
                  </div>
                  <div
                    class="col-8 my-2"
                    [ngClass]="{'text-danger': result.attributes.is_valid === 0, 'text-info': result.attributes.is_valid === 1}"
                  >
                    <fa-icon [icon]="faHourglass"></fa-icon>
                    <div *ngIf="result.attributes.expires_at; then thenShowExpiryDate else elseNoExpiration"></div>
                    <ng-template #thenShowExpiryDate
                      ><span>
                        {{'access-code.batch_expires_at' | translate}} {{helper.localizeDateTimestamp(result.attributes.expires_at,
                        'datetime')}}</span
                      ></ng-template
                    >
                    <ng-template #elseNoExpiration> {{'access-code.no_expiry-date' | translate }} </ng-template>
                    <span *ngIf="result.attributes.is_valid === 0" class="text-danger">
                      <fa-icon [icon]="faTimes"></fa-icon>
                    </span>
                    <span *ngIf="result.attributes.is_valid === 1" class="text-info">
                      <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                  </div>

                  <div class="col-8">
                    <p>
                      <strong>
                        <fa-icon [icon]="faClock"></fa-icon>
                        <span> {{'access-code.batch_created_at' | translate}}</span>
                        {{helper.localizeDateString(result.attributes.creation_date)}}
                      </strong>

                      <span class="text-info" *ngIf="!!result.attributes.user_id">
                        (<fa-icon [icon]="faUser"></fa-icon>
                        {{ 'access-code.created_by' | translate}} {{helper.getECoachUsername(result.attributes.user_id, collaborators) |
                        translate}})
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row mx-2 mb-2">
                  <div class="col-8">
                    <p>
                      <strong>
                        <fa-icon [icon]="faStarHalfStroke"></fa-icon>
                        <span> {{'access-code.numbers_of_used_codes' | translate}}</span>
                        [{{result.attributes.number_of_used_access_codes}}/{{result.attributes.number_of_access_codes}}]
                      </strong>
                    </p>
                  </div>
                  <div class="col-8">
                    <p>
                      <strong>
                        <fa-icon [icon]="faStar"></fa-icon>
                        <span> {{'access-code.numbers_of_verified_accounts_through_access_codes' | translate}}:</span>
                        {{result.attributes.number_of_used_and_confirmed_access_codes}}
                      </strong>
                    </p>
                  </div>
                </div>
                <div *ngIf="result.attributes.description" class="row mx-2 mb-2">
                  <strong>
                    <fa-icon [icon]="faInfoCircle"></fa-icon>
                    <span> {{'access-code.description' | translate}}:</span>
                  </strong>
                  <mat-form-field appearance="outline" class="mt-1 full-width">
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMaxRows="12" readonly>{{ result.attributes.description }}</textarea>
                  </mat-form-field>
                </div>
              </mat-card-content>
              <mat-card-actions *ngIf="result.attributes.is_valid === 1" align="end">
                <app-access-code-batch-invalidation (emitInvalidationPerformed)="loadAccessCodeBatch()" [_result]="result">
                </app-access-code-batch-invalidation>
              </mat-card-actions>
            </mat-card>
          </div>
        </ng-template>
        <ng-template #elseNoAccessCodes>
          <p class="text-center">{{'access-code.no_generated_access_codes' | translate}}</p>
        </ng-template>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div mat-dialog-actions *ngIf="selectedTabIndex === 0" align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'study-report.cancel' | translate}}</button>
  <app-button-feedback
    [attr.id]="'btnGenerateAccessCodeBatch'"
    [_isDisabled]="anyInvalidData()"
    (click)="generateAccessCodeBatch()"
    [_defaultButtonText]="'access-code.generate_access_code_batch'"
    [_responseText]="generateAccessCodeBatchResponse | async"
  ></app-button-feedback>
</div>
